import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const EditIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className} flex-shrink-0`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2419 6.03311C14.8513 5.64258 14.2182 5.64258 13.8276 6.0331C13.4371 6.42362 13.4371 7.05679 13.8276 7.44732L15.2419 6.03311ZM16.5838 10.2035C16.9743 10.5941 17.6075 10.5941 17.998 10.2035C18.3886 9.81301 18.3886 9.17985 17.998 8.78932L16.5838 10.2035ZM15.7053 5.57083L16.4124 6.27794L16.4124 6.27793L15.7053 5.57083ZM18.4614 8.32704L19.1685 9.03415L19.1685 9.03415L18.4614 8.32704ZM7.72851 19.06V20.06C7.99372 20.06 8.24808 19.9546 8.43561 19.7671L7.72851 19.06ZM5 19.06H4C4 19.6123 4.44772 20.06 5 20.06V19.06ZM5 16.2761L4.29289 15.569C4.10536 15.7565 4 16.0108 4 16.2761H5ZM13.8276 7.44732L16.5838 10.2035L17.998 8.78932L15.2419 6.03311L13.8276 7.44732ZM16.4124 6.27793C16.7829 5.90736 17.3838 5.90736 17.7543 6.27793L19.1685 4.86373C18.0169 3.71209 16.1498 3.71209 14.9981 4.86373L16.4124 6.27793ZM17.7543 6.27793C18.1249 6.64851 18.1249 7.24936 17.7543 7.61994L19.1685 9.03415C20.3202 7.88252 20.3202 6.01535 19.1685 4.86373L17.7543 6.27793ZM17.7543 7.61994L7.0214 18.3529L8.43561 19.7671L19.1685 9.03415L17.7543 7.61994ZM7.72851 18.06H5V20.06H7.72851V18.06ZM6 19.06V16.2761H4V19.06H6ZM5.70711 16.9832L16.4124 6.27794L14.9982 4.86372L4.29289 15.569L5.70711 16.9832Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;
